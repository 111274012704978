
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';

import ListItem from '@/ui/components/ListItem';
import { WithSportAndTemplate } from '@/ui/mixins/WithSportAndTemplate';
import { MarketStore } from '@/modules/market/market.store';
import { marketService } from '@/modules/market/market.service';
import { WithFilters } from '@/ui/mixins/WithFilters';
import { SportStore, SportFilterType } from '@/modules/sport';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';

import { InputConfirm } from '@/ui/components/InputConfirm';

// THIS WILL BE DELETED WHEN BACKEND SOLVES THIS ISUE WITH PLAYERS MARKET '2TYPES'
import { PLAYER_MARKETS_2TYPES, GROUP_TYPE } from '@/modules/market/market.constants';
import { SubMarket } from '@/modules/market';

@Component({ components: { ListItem, InputConfirm } })
export default class MarketsTemplatesPage extends Mixins(
  WithSportAndTemplate,
  WithFilters,
  WithNetworkStatus
) {
  form!: WrappedFormUtils;
  selectedMarket = 0;
  templateType: 'LIVE' | 'PREMATCH' | 'PLAYER' = 'LIVE';

  // THIS WILL BE DELETED WHEN BACKEND SOLVES THIS ISUE WITH PLAYERS MARKET '2TYPES'
  get customPlayerMarketShow() {
    if (this.templateType === 'PLAYER' && this.sports && this.markets) {
      const sport = this.sports.find((sport: { id: number }) => sport.id === this.selectedSport);
      if (!sport) return null;

      const market = this.markets.find(
        (market: { id: number }) => market.id === this.selectedMarket
      );
      if (!market) return null;
      if (PLAYER_MARKETS_2TYPES[sport.code].includes(market.name)) {
        return true;
      }
      return null;
    }
    return null;
  }

  get isLive() {
    return this.templateType === 'LIVE';
  }

  get playerMarkets() {
    if (!this.selectedSport) return [];
    return MarketStore.singleSportPlayerMarkets(this.selectedSport);
  }

  get sports() {
    if (this.templateType === 'PLAYER') {
      return SportStore.sortedAllSports.filter((sport: SportFilterType) => sport.is_player);
    }
    if (this.templateType === 'LIVE') {
      return SportStore.sortedAllSports.filter((sport: SportFilterType) => sport.is_live);
    }

    if (this.templateType === 'PREMATCH') {
      return SportStore.sortedAllSports.filter((sport: SportFilterType) => sport.is_prematch);
    }
    return SportStore.sortedAllSports;
  }

  get markets() {
    if (!this.selectedSport) return [];
    if (this.templateType === 'PLAYER') return this.playerMarkets;
    return MarketStore.singleSportMarkets(this.selectedSport, this.isLive);
  }

  get isNewTemplate() {
    return this.template?.id === -1;
  }

  get templates() {
    if (!this.selectedSport) return [];
    return MarketStore.sportTemplates(this.selectedSport, this.templateType);
  }

  get template(): any {
    if (!this.selectedSport) return {};
    return MarketStore.templates.find((t: any) => t.id === this.selectedTemplate);
  }

  get isMarketSelected() {
    return (marketId: number) => this.selectedMarket === marketId;
  }

  get subMarkets() {
    return marketService.getMarketSubmarkets(this.selectedMarket, this.markets);
  }

  get marketState() {
    return (market: any) => marketService.calculateMarketCheckState(market, this.template);
  }

  get subMarketState() {
    return (subMarket: any) =>
      marketService.calculateSubMarketCheckState(subMarket, this.template, this.selectedMarket);
  }

  // THIS WILL BE DELETED WHEN BACKEND SOLVES THIS ISUE WITH PLAYERS MARKET '2TYPES'
  get subMarketState2Types() {
    return (subMarkets: any) => {
      const first = marketService.calculateSubMarketCheckState(
        subMarkets[0],
        this.template,
        this.selectedMarket
      );
      const second = marketService.calculateSubMarketCheckState(
        subMarkets[1],
        this.template,
        this.selectedMarket
      );
      if (first === 'checked' && second === 'checked') return 'checked';
      return 'unchecked';
    };
  }

  get isAllMarketSelected() {
    if (!this.markets) return false;
    let result = true;
    this.markets.forEach((market: any) => {
      if (this.marketState(market) !== 'checked') {
        result = false;
      }
    });
    return result;
  }

  get selectLabel() {
    return this.isAllMarketSelected ? this.$t('market.deselectAll') : this.$t('market.selectAll');
  }

  get submarketLabel() {
    return (m: SubMarket) => marketService.getSubmarketsLabel(m);
  }

  // THIS WILL BE DELETED WHEN BACKEND SOLVES THIS ISUE WITH PLAYERS MARKET '2TYPES'
  get customSubMarketsList() {
    return (subMarkets: any) => {
      const newSubMarkets = subMarkets.reduce((acc: any, item: any): any => {
        const i = GROUP_TYPE.findIndex(prop => prop.includes(item.type_code));
        if (i < 0) return [...acc];
        const arr = [];
        if (acc[i]) acc[i].push(item);
        else {
          arr.push(item);
          acc[i] = arr;
        }
        return [...acc];
      }, []);
      return newSubMarkets;
    };
  }

  @Watch('selectedSport')
  onSportSelect() {
    if (!this.selectedSport) return;
    MarketStore.getPlayerMarkets(this.selectedSport);
    MarketStore.getSportMarkets(this.selectedSport);
    MarketStore.removeUnsavedTemplates();
    this.form.resetFields();
    this.selectedMarket = 0;
  }

  @Watch('selectedTemplate')
  onTemplateSelect() {
    if (!this.selectedTemplate) return;
    this.form.setFieldsValue({ name: this.template?.name, is_default: this.template?.is_default });
    this.selectedMarket = 0;
  }

  @Watch('templateType')
  onTemplateTypeChange() {
    this.selectedTemplate = null;
    this.selectedMarket = 0;
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  mounted() {
    MarketStore.getTemplates();
  }

  selectMarket(id: number) {
    this.selectedMarket = id;
  }

  toggleSubMarkets(value: boolean, subMarket: any) {
    MarketStore.togleSubMarket({
      activeTemplate: this.selectedTemplate,
      marketId: this.selectedMarket,
      subMarket,
      value,
    });
  }

  // THIS WILL BE DELETED WHEN BACKEND SOLVES THIS ISUE WITH PLAYERS MARKET '2TYPES'
  toggleSubMarkets2Types(value: boolean, subMarkets: any) {
    subMarkets.forEach((sub: any) => {
      MarketStore.togleSubMarket({
        activeTemplate: this.selectedTemplate,
        marketId: this.selectedMarket,
        subMarket: sub,
        value,
      });
    });
  }

  toggleMarket(value: boolean, marketId: number) {
    if (!this.selectedTemplate) return;

    MarketStore.toggleSubMarkets({
      activeTemplate: this.selectedTemplate,
      marketId,
      sportId: this.selectedSport ?? 0,
      value,
      isLive: this.isLive,
      isPlayer: this.templateType === 'PLAYER',
    });
  }

  createTemplate() {
    if (!this.selectedSport) return;
    MarketStore.newTemplate({ sportId: this.selectedSport, template_type: this.templateType });
    this.form.resetFields();
    this.selectedTemplate = -1;
  }

  async onSave() {
    const [err] = await to(this.form.validateFields() as any);
    if (err) return;
    const formData = this.form.getFieldsValue();

    this.setNetworkStatus(NetworkStatus.InProgress);

    const [error] = await to(
      MarketStore.saveTemplate({
        id: this.selectedTemplate,
        name: formData.name,
        sport_id: this.selectedSport,
        template_type: this.templateType,
        is_default: formData.is_default,
      })
    );

    if (formData.is_default) MarketStore.removeDefault(this.selectedTemplate);

    this.setNetworkStatus(NetworkStatus.Success);
    if (error) {
      this.errorMessage(this.$t('market.chooseMarketFirst') as string);
      return;
    }

    this.successMessage(this.$t('market.marketSaved') as string);
    if (this.isNewTemplate) {
      this.selectedTemplate = 0;
      this.selectedMarket = 0;
    }
  }

  onCopy() {
    if (!this.selectedSport || !this.selectedTemplate) return;
    MarketStore.copyToNewTemplate(this.selectedTemplate);
    this.form.resetFields();
    this.selectedTemplate = -1;
  }

  errorMessage(messageDescription: string) {
    this.$notification.error({
      message: this.$t('common.error') as string,
      description: messageDescription,
    });
  }

  successMessage(messageDescription: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: messageDescription,
    });
  }

  setTemplateType({ target }: any) {
    this.templateType = target.value;
  }

  toggleAllMarkets() {
    const nextVal = !this.isAllMarketSelected;
    this.markets.forEach((market: any) => {
      this.toggleMarket(nextVal, market.id);
    });
  }

  setDefault() {
    const formData = this.form.getFieldsValue();
    this.form.setFieldsValue({ is_default: !formData.is_default });
  }
}
